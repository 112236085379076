import React from "react"
import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import VideosSectionUpdate from "../../components/sections/VideosSectionUpdate";

const ExitTrimsVideosPage = () => (
  <MainContainer>
    <HeadDataNew 
      title='Exit Trims Videos Page' 
      description='A list of Command Access Exit Trims videos.' 
    />

    <VideosSectionUpdate 
      title="Exit Trims"
      category="Exit Trims"
      noVideos
    />
  </MainContainer>
)

export default ExitTrimsVideosPage